import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import {Title} from "../../components/elements/Title/title";
import {Breadcrumbs} from "../../components/modules/Breadcrumbs/breadcrumbs";
import {Rating} from "../../components/modules/Rating/rating";
import Image from "../../components/modules/Image/image.js";
import WidgetBanks from "../../components/modules/WidgetBanks/widgetBanks.js";
import RichText from "../../components/modules/RichText/richText";
import LeafletMap from "../../components/modules/Map/leafletMap";
import Faq from "../../components/modules/Faq/faq";
import List from "../../components/modules/List/list.js";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";

class BankOffices extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;

        const breadcrumbs = this.props.pageContext.data.breadcrumbs;
        const mainImage = this.props.pageContext.data.bank_image || false;
        const mainImageAlt = this.props.pageContext.data.bank_name || false;
        const mainTitle = seo.mainTitle || false;
        const mainText = this.props.pageContext.mainText || false;
        const subTitle = seo.subTitle || false;
        const subTitleMap = seo.subTitleMap || false;
        const subTitleList = seo.subTitleList || false;
        const rating = this.props.pageContext.rating || false;
        const listTable = this.props.pageContext.listTable;
        const listTableCol = this.props.pageContext.listTableCol || 1;
        const dataMap = this.props.pageContext.dataMap || false;
        const asks = this.props.pageContext.asks || false;

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module bgPr>
                    <Wrapper white>
                        <Breadcrumbs content={breadcrumbs} host={seo.host}/>
                        <JsonBreadcrumbs data={breadcrumbs} host={seo.host}/>

                        <Column row>
                            {mainImage && (
                                <Column col="fifth" colSm="half" colXs="full">
                                    <Image filename={mainImage} alt={mainImageAlt} />
                                </Column>
                            )}
                            <Column col="fifth-complete" colSm="half" colXs="full">
                                <Title tag={`h1`} size35 content={mainTitle}/>
                            </Column>
                        </Column>
                        <Column row>
                            {rating && (
                                <Column colXs="full" colSm="full">
                                    <Rating label={textStatic.rating_title} content={rating + ` ` + textStatic.rating_count}/>
                                </Column>
                            )}

                            <Column col="forth-complete" colSm="full">
                                <Box bgPr borderTop>
                                    {subTitle && (
                                        <Title tag={`h2`} size20 content={subTitle} />
                                    )}

                                    {mainText && (
                                        <RichText content={mainText} />
                                    )}

                                    {subTitleMap && (
                                        <>
                                        <Title tag={`h2`} size20 content={subTitleMap} />
                                        <LeafletMap dataMap={dataMap} textStatic={textStatic} zoom={15}/>
                                        </>
                                    )}

                                    {subTitleList && (
                                        <Title tag={`h2`} size20 content={subTitleList} />
                                    )}


                                    <List col={listTableCol} colSm={1} data={listTable} />

                                    {asks && (

                                        <Faq asks={asks} title={textStatic.faq_title}/>
                                    )}
                                </Box>
                            </Column>
                            <Column col="forth" colSm="full">
                                <Box bgPr>
                                    <WidgetBanks />
                                </Box>
                            </Column>
                        </Column>
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default BankOffices;